<template>
    <div>
        <div>
            <card title="General" body-class="flex-wrap overflow-visible" class="border-radius">
                <div>
                    <image-input @update="fetchUpdateAvatar" @delete="fetchDeleteAvatar" :image="image" :loading="loadingImage" class="mr-8" :title="user.name[0]" />
                </div>

                <div class="flex flex-grow flex__column">
                    <!-- Name -->
                    <modal-field :label="$t('name')" class="mb-4">
                        <div class="col-md-7">
                            <input v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }" class="form-control" type="text" name="name">
                            <has-error :form="form" field="name" />
                        </div>
                    </modal-field>

                    <!-- Email -->
                    <modal-field :label="$t('email')" class="mb-4">
                        <div class="col-md-7">
                            <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" class="form-control" type="email" name="email">
                            <has-error :form="form" field="email" />
                        </div>
                    </modal-field>

                    <!-- Phone -->
                    <div class="flex mt-4 phone-number">
                        <vue-phone-number-input v-model="phonePart" @update="updatePhone" no-validator-state color="#636363" :default-country-code="user.phone_country" />
                        <has-error :form="form" field="phone" />
                    </div>

                    <!-- Basic Currency -->
                    <modal-field label="Basic currency" class="mb-4">
                        <multiselect
                            v-model="form.basic_currency"
                            :options="basicCurrencyOptions"
                        />
                    </modal-field>
                </div>

                <div class="flex flex-grow flex__column ml-6">
                    <modal-field label="User roles" class="mb-4">
                        <multiselect
                            v-model="userRoles"
                            multiple
                            track-by="key"
                            label="name"
                            :options="userRolesOptions"
                            disabled
                        >
                        </multiselect>
                    </modal-field>

                    <modal-field label="Organization" class="mb-4">
                        <multiselect
                            v-model="user.organization"
                            track-by="id"
                            label="name"
                            :options="[]"
                            disabled
                        >
                        </multiselect>
                    </modal-field>

                    <modal-field label="Sites available" class="mb-4">
                        <multiselect
                            v-model="userSites"
                            :options="sitesOptions"
                            label="name"
                            track-by="id"
                            disabled
                            multiple
                        >
                        </multiselect>
                    </modal-field>

                    <modal-field label="Area of responsibility">
                        <input type="text" v-model="form.description" >
                    </modal-field>
                </div>

                <!-- Submit Button -->
                <div class="form-group w-full mb-0 mt-4">
                    <button :loading="form.busy" @click.prevent="update" class="btn ml-auto">
                        {{ $t('update') }}
                    </button>
                </div>
            </card>

            <password></password>

        </div>
    </div>

</template>

<script>
import Form from 'vform';
import Password from "./password";
import axios from "axios";
import ModalField from "~/components/ModalField";
import ImageInput from "~/components/ImageInput";
import VuePhoneNumberInput from "vue-phone-number-input";

import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
    components: { ModalField, Password, ImageInput, VuePhoneNumberInput },
    scrollToTop: false,

    metaInfo () {
        return { title: this.$t('settings') }
    },

    data: () => ({
        form: new Form({
            name: '',
            email: '',
            phone: '',
            phone_country: 'SE',
            description: '',
            basic_currency: '',

        }),
        phonePart: '',
        image: null,
        loadingImage: false,
        userLevel: null,
        userLevels: [
            {
                name: 'Organization administrator',
                key: 'organization-admin',
            },
            {
                name: 'vChain adminstrator',
                key: 'super'
            }
        ],
        userRoles: [],
        userRolesOptions: [],
        userSites: [],
        sitesOptions: [],
        basicCurrencyOptions: [
            'SEK', 'EUR', 'GBP', 'USD', 'DKK'
        ],
    }),

    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
        roles() {
            return this.$store.getters['auth/roles'];
        },
        sites() {
            return this.$store.getters['auth/sites'];
        },
    },

    async created () {
        // Fill the form with user data.
        this.form.keys().forEach(key => this.form[key] = this.user[key]);
        this.phonePart = this.user.phone;
        this.image = `${process.env.VUE_APP_API}/api/users/${this.user.id}/avatar?${Date.now()}`;

        this.parseUserLevel();

        this.fetchRoles();
        this.fetchSites();
    },

    methods: {
        async fetchRoles() {
            if (this.roles.find(role => ['GOD USERS', ''].includes(role.name))) {
                return;
            }

            this.userRoles = this.user.roles;

            const { data: { data } } = await axios.get(this.$apiUrl.roles.base + '?pageSize=1000');

            let roles = data;
            if (!this.roles.find(role => role.name === 'GOD USERS')) {
                roles?.filter(role => role.name !== 'GOD USERS');
            }

            this.userRolesOptions = roles;
        },
        async fetchSites() {
            this.userSites = this.sites;

            const { data: { data } } = await axios.get(this.$apiUrl.sites.allowed + `?pageSize=1000`);
            this.sitesOptions = data;
        },
        parseUserLevel() {
            this.userLevel = this.userLevels.find(item => { return item.key === this.user.level });
        },

        updatePhone(payload) {
            this.form.phone_country = payload.countryCode;
            this.form.phone = payload.formattedNumber;

            if (!payload.isValid) {
                this.form.errors.set('phone', 'Invalid phone number');
            } else {
                this.form.errors.clear('phone');
            }
        },

        async fetchDeleteAvatar() {
            this.loadingImage = true;

            try {
                await axios.delete(this.$apiUrl.settings.avatar(this.user.id));
                this.image = '';

                this.$store.commit('auth/CHANGE_AVATAR');
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            } finally {
                this.loadingImage = false;
            }
        },

        async fetchUpdateAvatar(value) {
            this.loadingImage = true;

            try {
                const formData = new FormData();
                formData.append('avatar', value);

                await this.fetchDeleteAvatar();

                await axios.post(this.$apiUrl.settings.avatar(this.user.id), formData);

                this.image = `${process.env.VUE_APP_API}/api/users/${this.user.id}/avatar?${Date.now()}`;

                this.$store.commit('auth/CHANGE_AVATAR');
            } catch(error) {
                this.$snotify.error(error?.response?.data?.errors?.avatar[0] || error?.response?.data?.message);
            } finally {
                this.loadingImage = false;
            }
        },

        async update () {
            if (this.form.errors.any()) {
                return;
            }

            const payload = {
                name: this.form.name,
                email: this.form.email,
                phone: this.form.phone,
                phone_country: this.form.phone_country,
                basic_currency: this.form.basic_currency,
                site_ids: this.userSites?.map(site => site.id)?.join(','),
                description: this.form.description,
            };
            const { data: { data } } = await axios.patch(this.$apiUrl.settings.profile, payload);

            this.$store.dispatch('auth/fetchUser');
            this.$snotify.success("Updated");

            return data;
        },
    },
}
</script>

<style lang="scss">
.profile-notifications {
    .card-body {
        padding-left: 0;
        padding-right: 0;
    }
}
.phone-number {
    margin-bottom: 27px;
    .vue-phone-number-input {
        width: 100%;

        label {
            top: -16px;
            left: 0;
        }
        input {
            padding-top: 0 !important;
            box-shadow: none !important;
            border-color: #E6E6E6;
        }
        input,
        .country-selector,
        .input-tel {
            min-height: 33px;
            height: 33px;
        }
        .country-selector__country-flag {
            top: 8px;
        }
        .country-selector__toggle {
            top: calc(50% - 12px);
        }
    }

    .country-selector__list {
        .vue-recycle-scroller {
            position: relative;
        }
        .vue-recycle-scroller.ready .vue-recycle-scroller__item-view {
            position: relative;
            will-change: auto;
            height: 40px;
        }
    }

    .help-block.invalid-feedback {
        --text-opacity: 1;
        color: #f56565;
        color: rgba(245, 101, 101, var(--text-opacity));
    }
}
</style>
